import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import PostList from '../components/PostList';
import TextHeader from '../components/TextHeader';

const Resources = ({ data }) => (
    <Layout {...data.site.siteMetadata}>
        <Seo
            pageTitle="Model Resources"
            description="Find resources to support your journey into online scat modelling on KinkyScat.com!"
            {...data.site.siteMetadata}
        />
        <TextHeader
            title="Model Resources"
            subtitle="Find curated resources to support your scat modelling career!"
        />
        <PostList
            posts={data.allMarkdownRemark.nodes.map(p => ({
                title: p.frontmatter.title,
                description: p.frontmatter.description,
                href: p.fields.slug,
            }))}
        />
    </Layout>
);

export default Resources;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
                mainSiteUrl
                social {
                    twitter
                    reddit
                    rss
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { type: { eq: "resource" } } }
        ) {
            nodes {
                frontmatter {
                    title
                    description
                }
                fields {
                    slug
                }
            }
        }
    }
`;

import React, { FC } from 'react';

type Post = {
    title: string;
    description: string;
    href: string;
};

const PostList: FC<{ posts: Post[] }> = ({ posts }) => (
    <div className="grid gap-8 pt-5 mt-3 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-6">
        {posts.map(post => (
            <div key={post.title}>
                {/* <p className="text-sm text-gray-500">
                    <time dateTime={post.datetime}>{post.date}</time>
                </p> */}
                <a href={post.href} className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">
                        {post.title}
                    </p>
                    <p className="mt-3 text-base text-gray-500">
                        {post.description}
                    </p>
                </a>
                <div className="mt-3">
                    <a
                        href={post.href}
                        className="text-base font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                        Read full post
                    </a>
                </div>
            </div>
        ))}
    </div>
);

export default PostList;
